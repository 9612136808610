<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body class="pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            lg="3"
            class="mb-md-0 mb-50"
          >
            <label>Nhóm người dùng</label>
            <v-select
              v-model="roleFilter"
              :options="roles"
              class="w-100 select-size-sm"
              label="name"
              :reduce="val => val.code"
              @input="(val) => $emit('update:roleFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            sm="4"
            lg="3"
            class="mb-md-0 mb-50"
          >
            <label>Phòng ban</label>
            <v-select
              v-model="departmentFilter"
              :options="departments"
              class="w-100 select-size-sm"
              label="name"
              :reduce="val => val.code"
              @input="(val) => $emit('update:roleFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            sm="4"
            lg="6"
            class="mb-md-0 mb-50"
          >
            <label>Nội dung</label>
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="icons-search"
                  v-model="searchQuery"
                  :autofocus="true"
                  size="sm"
                  placeholder="Tên, email, số đt"
                  @keydown.enter.prevent="searchKey"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="cursor-pointer"
                    @click="searchKey"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <h4 class="text-primary">
          DANH SÁCH NGƯỜI DÙNG
        </h4>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="text-nowrap"
          @click="popupAddUser"
        >
          <feather-icon
            class="text-white"
            icon="PlusIcon"
          />
          Nhân viên
        </b-button>
      </b-card-header>
      <b-table
        ref="refUsersListTable"
        class="position-relative"
        responsive
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="users"
        :fields="tableColumns"
      >

        <!-- Column: Người dùng -->
        <template #cell(nguoi-dung)="data">
          <b-media
            vertical-align="center"
            class="align-items-stretch"
          >
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullname)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                class="cursor-pointer"
                @click="popupEdit(data.item)"
              />
            </template>
            <div class="font-weight-bold text-primary d-flex flex-column justify-content-between h-100">
              <span
                class="cursor-pointer"
                @click="popupEdit(data.item)"
              >
                {{ data.item.fullname }}
              </span>
              <small class="text-muted">{{ data.item.phone }}</small>
            </div>
          </b-media>
        </template>

        <!-- Column: Nhóm người dùng -->
        <template #cell(nhom-nguoi-dung)="data">
          <div class="text-xl-nowrap text-primary font-small-4 mb-50">
            {{ data.item.user_role && data.item.user_role.name }}
          </div>
        </template>

        <!-- Column: Phòng ban -->
        <template #cell(phong-ban)="data">
          <div class="text-primary d-flex flex-column justify-content-between h-100">
            <span>
              {{ data.item.department && data.item.department.name }}
            </span>
            <small
              v-if="data.item.job_title_code"
              class="text-muted"
            >
              ({{ $userJobTitleOptions.find(u => u.value === data.item.job_title_code).label }})</small>
          </div>
        </template>

        <!-- Column: Trạng thái -->
        <template #cell(trang-thai)="data">
          <b-badge
            :class="'bg-light-' + (data.item.is_locked ? 'warning' : 'primary') + ' text-' + (data.item.is_locked ? 'warning' : 'primary')"
          >
            {{ data.item.is_locked ? 'Khóa' : 'Hoạt động' }}
          </b-badge>
        </template>

        <!-- Column: Hành động -->
        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="outline-danger"
            @click="openModalConfirmDeleteUser(data.item)"
          >Xóa
          </b-button>
        </template>
      </b-table>
    </b-card>

    <user-modal-info
      :is-show.sync="isPopupUserModal"
      :role-options="roles"
      :department-options="departments"
      :modal-mode="userModalMode"
      :modal-data="userModalData"
      @submit="submitForm"
    />

    <b-modal
      ref="modal-confirm-delete-user"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa người dùng!"
      @ok="deleteUser(currentUser)"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span> người dùng
        <span class="text-primary font-weight-bolder">{{ currentUser.fullname || currentUser.phone || currentUser.username }}</span>?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BMedia,
  BRow,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import userStoreModule from '@/views/settings/users/userStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import roleStoreModule from '@/views/settings/roles/roleStoreModule'
import { avatarText } from '@core/utils/filter'
import departmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import useReportList from './useUsersList'
import UserModalInfo from './SidebarUserDetail.vue'

export default {
  components: {
    UserModalInfo,
    BCardHeader,
    BCardBody,
    BInputGroup,
    BCardText,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BTable,
    BBadge,
    BAvatar,
    BMedia,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    this.$call(this.fetchUsers())
    this.$call(this.fetchDepartments())
  },
  methods: {
    openModalConfirmDeleteUser(u) {
      this.currentUser = u
      this.$refs['modal-confirm-delete-user'].show()
    },
    submitForm(modalMode, modalData) {
      if (modalMode === 'create') {
        this.$call(this.createUser(modalData), true)
      }
      if (modalMode === 'edit') {
        this.$call(this.updateUser(modalData), true)
      }
    },
    popupAddUser() {
      this.userModalMode = 'create'
      this.isPopupUserModal = true
      this.userModalData = {}
    },
    popupEdit(user) {
      this.userModalMode = 'edit'
      this.$call(this.fetchUser(user.id))
    },
  },
  setup() {
    const USER_STORE_MODULE_NAME = 'user'
    const ROLE_STORE_MODULE_NAME = 'role'
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(ROLE_STORE_MODULE_NAME)) store.registerModule(ROLE_STORE_MODULE_NAME, roleStoreModule)
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, departmentStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
      if (store.hasModule(ROLE_STORE_MODULE_NAME)) store.unregisterModule(ROLE_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
    })

    return {
      currentUser: ref({}),
      avatarText,
      ...useUsersList(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card-body .card-body {
  padding: .5rem;
}
</style>
